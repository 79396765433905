import React, { useState, useEffect } from "react";
import { Container, Row, Col, Label } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Input, Button, Notification } from "rsuite";
import "./Profile.css";
import { getConfig } from "../config";
import { NavLink } from "react-router-dom";
import axios from "axios";

export const ProfileComponent = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { apiOrigin } = getConfig();

  const [phone, setPhone] = useState("");
  const [farmerPublic, setFarmerPublic] = useState("");
  const [poolPublic, setPoolPublic] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  const loadProfileData = async () => {
    let token = await getAccessTokenSilently();
    try {
      const result = await axios.get(`${apiOrigin}/api/v1/users/metadata`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      setPhone(result.data.phone);
      setFarmerPublic(result.data.farmer_public_key);
      setPoolPublic(result.data.pool_public_key);
    } catch (error) {
      console.log(error);
    }
  };

  const showNotification = (funcName, message) => {
    Notification[funcName]({
      title: funcName,
      description: (
        <p style={{ width: 320 }} rows={3}>
          {message}
        </p>
      ),
    });
  };

  const saveProfileData = async () => {
    try {
      let token = await getAccessTokenSilently();
      await axios.post(
        `${apiOrigin}/api/v1/users/metadata`,
        {
          phone: phone,
          farmer_public_key: farmerPublic,
          pool_public_key: poolPublic,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showNotification(
        "success",
        "Your profile information has been updated successfully!"
      );
      if (props.kind === "order") {
        props.close();
      }
    } catch (error) {
      console.log(error);
      showNotification(
        "error",
        "Ups, something wrong, please try later again."
      );
    }
  };

  useEffect(() => {
    loadProfileData();
    return () => {};
  }, []);

  useEffect(() => {
    if (farmerPublic.length > 5 && poolPublic.length > 5) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [phone, farmerPublic, poolPublic]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Col className="profile-data-container">
          <Label for="profilePhone" className="input-label">
            Phone
          </Label>
          <Input
            onChange={(value) => setPhone(value)}
            name="profilePhone"
            type="text"
            className="profile-input"
            style={{ width: 300 }}
            placeholder="Insert a phone"
            value={phone}
          />
          <Label for="farmerPublic" className="input-label">
            Farmer-Public-Key
          </Label>
          <Input
            onChange={(value) => setFarmerPublic(value)}
            name="farmerPublic"
            type="text"
            className="profile-input"
            style={{ width: 300 }}
            placeholder="Insert the public farmer key"
            value={farmerPublic}
          />
          <Label for="poolPublic" className="input-label">
            Pool-Public-Key
          </Label>
          <Input
            onChange={(value) => setPoolPublic(value)}
            name="poolPublic"
            type="text"
            className="profile-input"
            style={{ width: 300 }}
            placeholder="Insert the public pool key"
            value={poolPublic}
          />
        </Col>
      </Row>
      <Row className="buttons-container">
        {!props.withoutCancel ? (
          <div>
            <NavLink to="/orders">
              <Button className="profile-button" appearance="subtle">
                Cancel
              </Button>
            </NavLink>
            <Button
              onClick={saveProfileData}
              className="profile-button"
              appearance="primary"
              disabled={disableSave}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
              onClick={saveProfileData}
              className="profile-button-large"
              appearance="primary"
              disabled={disableSave}
            >
              Save
            </Button>
        )}
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
