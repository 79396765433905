import React, { useState } from "react";
import "./SelectQuantity.css";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Modal, Button, Slider, InputNumber, FlexboxGrid, Notification } from "rsuite";
import axios from "axios";
import { getConfig } from "../config";

const SelectQuantity = ({ show, close }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [quantity, setQuantity] = useState(0);
  const { apiOrigin } = getConfig();

  const showNotification = (funcName, message) => {
    Notification[funcName]({
      title: funcName,
      description: <p style={{ width: 320 }} rows={3}>{message}</p>
    });
  }

  const createOrder = async () => {
    if (!quantity) return;
    try {
      let token = await getAccessTokenSilently();
      await axios.post(
        `${apiOrigin}/api/v1/orders`,
        {
          quantity_required: parseInt(quantity),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showNotification('success', "Your order has been created, you will receive soon an e-mail from us!")
      closeModal();
    } catch (error) {
      showNotification('error', "Ups, something wrong, please try later again.")
      console.log(error);
    }
  };

  const closeModal = () => {
    close()
    setQuantity(0)
  }

  const updateQuantity = (value) => {
    if (value > 100) return;
    setQuantity(value)
  }

  return (
    <div className="modalContainer">
      <Modal show={show} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>New order</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          <div className="modal-intruccion">
            <h5>Please select quantity requested</h5>
          </div>
          <FlexboxGrid className="slider-container">
            <FlexboxGrid.Item colspan={10}>
              <Slider className="mySlider" onChange={(value) => updateQuantity(value)} value={quantity} min={0} max={100} />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
              <InputNumber
                className="input-Number"
                min={0}
                max={100}
                value={quantity}
                onChange={(value) => updateQuantity(value)}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Modal.Body>
        <hr />
        <Modal.Footer>
          <Button onClick={close} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={createOrder}
            disabled={quantity ? false : true}
            appearance="primary"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withAuthenticationRequired(SelectQuantity, {
  onRedirecting: () => <Loading />,
});
