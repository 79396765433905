export function getConfig() {
  const audience =
    process.env.REACT_APP_audience && process.env.REACT_APP_audience !== "YOUR_API_IDENTIFIER"
      ? process.env.REACT_APP_audience
      : null;

  const apiOrigin = 
    process.env.REACT_APP_apiOrigin ? process.env.REACT_APP_apiOrigin : null;

  const chiaFrontApp = 
    process.env.REACT_APP_chiaFrontApp ? process.env.REACT_APP_chiaFrontApp : null;

  return {
    domain: process.env.REACT_APP_domain,
    clientId: process.env.REACT_APP_clientId,
    ...(audience ? { audience } : null),
    ...(apiOrigin? { apiOrigin } : null),
    ...(chiaFrontApp? { chiaFrontApp } : null),
  };
}
