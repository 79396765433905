import { useState } from 'react';

export default function useModal() {
    const [showModal, setShowModal] = useState(false);

    function close() {
        setShowModal(false)
    };

    function open() {
        console.log("pase")
        setShowModal(true)
    };

    return {
        showModal,
        close,
        open
    }
}


