import React from "react";
import './Loading.css'

const Loading = () => (
  <div className="spinner2">
    <div className="loader"></div>
  </div>
);

export default Loading;
