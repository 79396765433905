import React, { useState, useEffect } from "react";
import { IconButton, Icon, Message, Modal, Notification, Button, ButtonToolbar, Row } from "rsuite";
import { Table } from "rsuite";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import Loading from "../components/Loading";
import { getConfig } from "../config";
import "./Orders.css";
import SelectQuantity from "./SelectQuantity";
import useModal from "./useModal";
import Profile from "./Profile";
import { Progress } from 'rsuite';
import { Grid } from 'rsuite';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const { Circle } = Progress;
const { Column, HeaderCell, Cell } = Table;

const Orders = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { apiOrigin } = getConfig();

  const [orders, setOrders] = useState({
    orders: [],
    plots: [],
    status: []
  });

  const [loading, setLoading] = useState(<Loading />);

  const [modalProfile, setModalProfile] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const downloadPlot = (link) => {
    var anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = Date() + "_plot";
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(anchor);
    }, 200);
  };

  const loadProfileData = async () => {
    let token = await getAccessTokenSilently();
    try {
      await axios.get(`${apiOrigin}/api/v1/users/metadata`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      setModalProfile(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setModalProfile(true);
      }
    }
  };

  const deleteFile = async(filename, id_file) => {
    let token = await getAccessTokenSilently();
    try {
      await axios.delete(`${apiOrigin}/api/v1/plots/${filename}?id_file=${id_file}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      Notification["success"]({
        title: "Success",
        description: <p>File deleted successfully, thank you!</p>
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: <p>Ups, somthing went wrong, please try later.</p>
      });
    }
  }

  const deletePlot = (item) => {
    Notification.open({
      title: 'Delete',
      duration: 0,
      description: (
        <div>
          <p>Do you want to delete this file forever?</p>
          <ButtonToolbar>
            <Button
              onClick={() => {
                deleteFile(item.name, item.id_file);
                Notification.close();
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                Notification.close();
              }}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </div>
      )
    });
  }

  const loadOrders = async () => {
    let token = await getAccessTokenSilently();
    try {
      const result = await axios.get(`${apiOrigin}/api/v1/orders`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      let ordersList = result.data;
      ordersList["orders"] = !ordersList["orders"] ? [] : ordersList["orders"];
      ordersList["plots"] = !ordersList["plots"] ? [] : ordersList["plots"].filter(x => x.name.endsWith(".plot"));
      ordersList["status"] = !ordersList["status"] ? [] : ordersList["status"];

      setOrders(ordersList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(true);
      console.log(error);
    }
  };

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    Notification["success"]({
      title: "Success",
      description: <p>Link copied to clipboard successfully!</p>
    });
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const formatDate = (date) => { 
    return date.split(".")[0].split("T").join(" ");
  }

  const convertPorcentageToNumber = (str) => {
    return parseFloat(str)
  }

  useEffect(() => {
    loadProfileData();
    loadOrders();
    const ordersFetchInterval = setInterval(() => loadOrders(), 3000);
    return () => {
      clearInterval(ordersFetchInterval);
    };
  }, []);

  const { open, close, showModal } = useModal();

  return (
    <div className="order-container show-grid">
      {loading}
      <Grid fluid>
        <Modal show={modalProfile}>
          <Modal.Body>
            <div>
              <Profile withoutCancel={true} kind="order" close={() => setModalProfile(false)} />
            </div>
          </Modal.Body>
        </Modal>
        <SelectQuantity close={close} show={showModal} />
        {!loading && errorMessage === "" &&
          <Tabs>
            <TabList>
              <Tab>
                Orders  { orders.orders && orders.orders.length > 0 &&
                  "("+orders.orders.length+")"
                }
              </Tab>
              <Tab>
                Status { orders.status && orders.status.length > 0 &&
                  "("+orders.status.length+")"
                }
              </Tab>
              <Tab>
                Downloads { orders.plots && orders.plots.length > 0 &&
                  "("+orders.plots.length+")"
                }
              </Tab>
            </TabList>

            <TabPanel>
                {/* Orders table start */}
                {orders.orders && orders.orders.length > 0 && errorMessage === "" &&
                  <div className="order-wrap chia-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Farmer Public Key</th>
                          <th>Pool Public Key</th>
                          <th>Quantity</th>
                          <th>Status</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          orders.orders.map(rowData => {
                            return (
                              <tr>
                                <td className="row-item">{rowData.ID}</td>
                                <td className="row-item elipsed-text">{rowData.farmer_public_key}</td>
                                <td className="row-item elipsed-text">{rowData.pool_public_key}</td>
                                <td className="row-item">{rowData.quantity_required}</td>
                                <td className="row-item">{rowData.status}</td>
                                <td className="row-item">{formatDate(rowData.created_at)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                }
            </TabPanel>
            <TabPanel>
              {/* Workers STATUS START */}
              {!loading && errorMessage === "" && orders.status && orders.status.length > 0 && (
                <div className="progress-container">
                  { orders.status.map(rowData => {
                    return (
                      <div className="progress-info">
                        <span className="progress-text">
                          {rowData.id}
                        </span>
                        <span className="circle-progress">
                          { (rowData.status === "100%")? 
                            <Circle percent={convertPorcentageToNumber(rowData.status)} strokeColor="#008df4" status="success" />
                            :
                            <Circle percent={convertPorcentageToNumber(rowData.status)} strokeColor="#008df4" />
                          }
                        </span>
                      </div>
                    )
                  })
                  }
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {/* plots table start */}
              { orders.plots && orders.plots.length > 0 && errorMessage === "" &&
                <div className="order-wrap chia-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Filename</th>
                        <th>Size</th>
                        <th>Date</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { 
                        orders.plots.map(rowData => {
                          return (
                            <tr>
                              <td className="row-item elipsed-text">{rowData.name}</td>
                              <td className="row-item">{formatBytes(rowData.size)}</td>
                              <td className="row-item">{rowData.uploaded_at}</td>
                              <td className="row-item">
                                <div className="btn-download" onClick={() => downloadPlot(rowData.link)} title="Download file">
                                  <Icon icon='download' />
                                </div>  
                              </td>
                              <td className="row-item">
                                <div className="btn-download" onClick={() => deletePlot(rowData)} title="Delete file">
                                  <Icon icon='trash' />
                                </div>
                              </td>
                              <td className="row-item">
                                <div className="btn-download" title="Copy to clipboard" onClick={() => { copyToClipboard(rowData.link)}}>
                                  <Icon icon='copy' />
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              }
            </TabPanel>
          </Tabs>
        }

        {!loading && errorMessage === "" && orders.orders && orders.orders.length === 0 &&
          <div className="orders-wrap">
            <Message
              showIcon
              type="info"
              title="Empty Orders"
              description="Nothing to show yet"
            />
            <div className="orders-actions">
              <IconButton
                onClick={() => open()}
                className="btn-order"
                icon={<Icon icon="plus" />}
                circle
                size="lg"
              />
            </div>
          </div>
        }
        {!loading && errorMessage !== "" &&
            <div className="orders-wrap">
              <Message
                showIcon
                type="error"
                title="Error"
                description="Ups, something is wrong, please try again later."
              />
              <div className="orders-actions">
                <IconButton
                  onClick={() => open()}
                  className="btn-order"
                  icon={<Icon icon="plus" />}
                  circle
                  size="lg"
                />
              </div>
            </div>
        }

        <div className="orders-actions">
          <IconButton
            onClick={() => open()}
            className="btn-order"
            icon={<Icon icon="plus" />}
            circle
            size="lg"
          />
        </div>
      </Grid>
    </div>
  );
};

export default withAuthenticationRequired(Orders, {
  onRedirecting: () => <Loading />,
});
